.svc-action-button {
  @include ctrDefaultBoldFont;
  display: inline-block;

  padding-top: var(--ctr-actionbar-button-padding-top-medium-text, calcSize(0.5));
  padding-inline-end: var(--ctr-actionbar-button-padding-right-medium-text, calcSize(2));
  padding-bottom: var(--ctr-actionbar-button-padding-bottom-medium-text, calcSize(0.5)); 
  padding-inline-start: var(--ctr-actionbar-button-padding-left-medium-text, calcSize(2));

  box-sizing: border-box;
  border-radius: calcSize(12.5);

  color: var(--ctr-survey-action-button-text-color-positive, $primary);

  border: calcSize(0.25) solid transparent;

  &:focus,
  &:hover {
    background-color: var(--ctr-survey-action-button-background-color-hovered-positive, $primary-light);
    outline: none;
  }
}

.svc-action-button--selected {
  border-color: var(--ctr-survey-action-button-text-color-positive, $primary);
}

.svc-action-button--disabled {
  opacity: var(--ctr-survey-action-button-opacity-disabled, 0.25);
  color: var(--ctr-survey-action-button-text-color-disabled, $foreground);
}

.svc-action-button--pressed {}