@import "../variables.scss";
@import "./carry-forward-panel.scss";

svc-question {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
}

.svc-question__adorner {
  position: relative;
  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  & > * {
    grid-row: 1;
    grid-column: 1;
  }
  &.svc-question__adorner--collapsed {
    height: fit-content;
  }

  .sv-action-bar {
    overflow: visible;

    .sv-action:not(:first-child) {
      .sv-action-bar-item {
        border: none;

        &:active {
          opacity: 0.5;
          background-color: $background-dim;
        }
      }
    }
  }
  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    margin-top: calcSize(-2);
    padding-top: calcSize(2);
    margin-left: calcSize(-2);
    padding-left: calcSize(2);
    bottom: 0;
    right: 0;
  }
}

.svc-survey-element-toolbar__item {
  @include defaultActionButtonSettings;
  --thm-survey-question-panel-toolbar-item-padding-top: calc(
    var(--ctr-survey-question-panel-toolbar-item-padding-top) +
      var(--ctr-survey-question-panel-toolbar-item-icon-padding-top)
  );
  --thm-survey-question-panel-toolbar-item-padding-right: calc(
    var(--ctr-survey-question-panel-toolbar-item-padding-right) +
      var(--ctr-survey-question-panel-toolbar-item-icon-padding-right)
  );
  --thm-survey-question-panel-toolbar-item-padding-bottom: calc(
    var(--ctr-survey-question-panel-toolbar-item-padding-bottom) +
      var(--ctr-survey-question-panel-toolbar-item-icon-padding-bottom)
  );
  --thm-survey-question-panel-toolbar-item-padding-left: calc(
    var(--ctr-survey-question-panel-toolbar-item-padding-left) +
      var(--ctr-survey-question-panel-toolbar-item-icon-padding-left)
  );
  --thm-survey-question-panel-toolbar-button-gap: calc(var(--ctr-survey-question-panel-toolbar-gap) / 2);

  margin: 0 var(--thm-survey-question-panel-toolbar-button-gap, calcSize(0.5));
  border-radius: var(--ctr-survey-question-panel-toolbar-item-corner-radius, calcSize(0.25));
  padding: var(--thm-survey-question-panel-toolbar-item-padding-top, calcSize(1))
    var(--thm-survey-question-panel-toolbar-item-padding-right, calcSize(1))
    var(--thm-survey-question-panel-toolbar-item-padding-bottom, calcSize(1))
    var(--thm-survey-question-panel-toolbar-item-padding-left, calcSize(1));

  justify-content: center;
  align-items: center;

  background-color: transparent;
  transition:
    background $creator-transition-duration,
    opacity $creator-transition-duration;
}

.svc-survey-element-toolbar__item--with-text {
  --thm-survey-question-panel-toolbar-item-padding-right: calc(
    var(--ctr-survey-question-panel-toolbar-item-padding-right-with-text) +
      var(--ctr-survey-question-panel-toolbar-item-icon-padding-right)
  );
  --thm-survey-question-panel-toolbar-item-gap: calc(
    var(--ctr-survey-question-panel-toolbar-item-gap) + var(--ctr-survey-question-panel-toolbar-item-icon-padding-right)
  );
  padding-inline-end: var(--thm-survey-question-panel-toolbar-item-padding-right, calcSize(1));
  gap: var(--thm-survey-question-panel-toolbar-item-gap, calcSize(0.5));
}

.svc-survey-element-toolbar .sv-action:last-of-type .svc-survey-element-toolbar__item {
  margin-inline-end: 0;
}

.svc-survey-element-toolbar .sv-action:first-of-type .svc-survey-element-toolbar__item {
  margin-inline-start: 0;
}

.svc-dropdown-action--convertTo .svc-survey-element-toolbar__item--with-text {
  --thm-survey-question-panel-toolbar-item-padding-left: calc(
    var(--ctr-survey-question-panel-toolbar-item-padding-left) +
      var(--ctr-survey-question-panel-toolbar-item-icon-padding-left-with-text)
  );
  --thm-survey-question-panel-toolbar-item-gap: calc(
    var(--ctr-survey-question-panel-toolbar-item-gap) + var(--ctr-survey-question-panel-toolbar-item-icon-padding-left)
  );

  flex-direction: row-reverse;
  padding-inline-start: var(--thm-survey-question-panel-toolbar-item-padding-left, calcSize(1));
  gap: var(--thm-survey-question-panel-toolbar-item-gap, calcSize(0.5));
}

.svc-dropdown-action--convertTo .svc-survey-element-toolbar__item--icon {
  padding: var(--ctr-survey-question-panel-toolbar-item-padding-top, calcSize(0.5))
    var(--ctr-survey-question-panel-toolbar-item-padding-right, calcSize(0.5))
    var(--ctr-survey-question-panel-toolbar-item-padding-bottom, calcSize(0.5))
    var(--ctr-survey-question-panel-toolbar-item-padding-left, calcSize(0.5));

  .svc-survey-element-toolbar-item__icon {
    padding: 0;
    width: var(--ctr-survey-question-panel-toolbar-item-icon-container-width, calcSize(3));
    height: var(--ctr-survey-question-panel-toolbar-item-icon-container-height, calcSize(3));
  }
}

.svc-survey-element-toolbar .svc-survey-element-toolbar-item__icon use {
  fill: var(--ctr-survey-question-panel-toolbar-item-icon-color, $secondary);
}

//hovered and focused state
.svc-survey-element-toolbar__item:not(.svc-survey-element-toolbar__item--pressed){
  &:hover:enabled {
    background-color: var(--ctr-survey-question-panel-toolbar-item-background-color-hovered, $background-dim);
  }
  &:focus:enabled {
    background-color: var(--ctr-survey-question-panel-toolbar-item-background-color-selected, $background-dim);
  }
}

//pressed state
.svc-survey-element-toolbar__item:not(.svc-survey-element-toolbar__item--pressed):active:enabled {
  opacity: var(--ctr-survey-question-panel-toolbar-item-opacity-pressed, 0.5);
  background-color: var(--ctr-survey-question-panel-toolbar-item-background-color-pressed, $background-dim);
  use {
    fill: black;
    opacity: 0.45;
  }
}

//disabled state
.svc-survey-element-toolbar__item:disabled {
  opacity: var(--ctr-survey-question-panel-toolbar-item-opacity-disabled, 0.25);
}

.svc-survey-element-toolbar__item--active {
  .svc-survey-element-toolbar-item__icon use {
    fill: var(--ctr-survey-question-panel-toolbar-item-icon-color, $secondary);
  }
}

.svc-survey-element-toolbar__item--pressed:not(.svc-survey-element-toolbar__item--active) {
  background-color: var(--ctr-survey-question-panel-toolbar-item-background-color-pressed, $background-dim);
  opacity: var(--ctr-survey-question-panel-toolbar-item-opacity-pressed, 50%);
  use {
    fill: black;
    opacity: 0.45;
  }
}

.svc-survey-element-toolbar__item--active:not(.svc-survey-element-toolbar__item--pressed):enabled:hover,
.svc-survey-element-toolbar__item--active:not(.svc-survey-element-toolbar__item--pressed):enabled:focus,
.svc-survey-element-toolbar__item--active {
  background-color: var(--ctr-survey-question-panel-toolbar-item-background-color-selected, $secondary-backcolor-semi-light);
}

.svc-survey-element-toolbar-item__icon,
.svc-survey-element-toolbar__dots-item .sv-svg-icon.sv-action-bar-item__icon {
  --ctr-survey-question-toolbar-item-icon-width: calc(
    var(--ctr-survey-question-panel-toolbar-item-icon-container-width) -
      var(--ctr-survey-question-panel-toolbar-item-icon-padding-right) -
      var(--ctr-survey-question-panel-toolbar-item-icon-padding-left)
  );
  --ctr-survey-question-toolbar-item-icon-height: calc(
    var(--ctr-survey-question-panel-toolbar-item-icon-container-height) -
      var(--ctr-survey-question-panel-toolbar-item-icon-padding-top) -
      var(--ctr-survey-question-panel-toolbar-item-icon-padding-bottom)
  );

  width: var(--ctr-survey-question-toolbar-item-icon-width, calcSize(2));
  height: var(--ctr-survey-question-toolbar-item-icon-height, calcSize(2));
}

.svc-survey-element-toolbar-item__title {
  @include lbrSmallBoldFont;
  color: var(--ctr-survey-question-panel-toolbar-item-text-color, $foreground);
  transition: color $creator-transition-duration;
}

.svc-question__adorner {
  .sv-action-bar-item-dropdown {
    background-color: transparent;
  }
}

.svc-question__adorner {
  .sd-input:disabled {
    pointer-events: none;
  }
}

.svc-question__content {
  scroll-margin-top: calcSize(1);

  .sd-element {
    scroll-margin-top: calcSize(5);
  }
}

.svc-question__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 100%;
  height: auto;
  flex-grow: 1;

  box-sizing: border-box;

  padding: var(--lbr-question-panel-padding-top, calcSize(4)) var(--lbr-question-panel-padding-right, calcSize(5))
    var(--ctr-survey-question-panel-padding-bottom-selected, calcSize(2)) var(--lbr-question-panel-padding-left, calcSize(5));
  border-radius: var(--lbr-question-panel-corner-radius, calcSize(0.5));
  background: var(--lbr-question-panel-background-color, $background);
  box-shadow: var(--lbr-question-panel-shadow-offset-x, 0px) var(--lbr-question-panel-shadow-offset-y, 1px)
    var(--lbr-question-panel-shadow-blur, 2px) var(--lbr-question-panel-shadow-spread, 0px)
    var(--lbr-question-panel-shadow-color, rgba(0, 0, 0, 0.15));

  outline: none;
  transition:
    background $creator-transition-duration,
    box-shadow $creator-transition-duration;
}
.svc-question__content--title-bottom {
  padding-top: calcSize(2.5);
  padding-bottom: calcSize(4);
  .sd-question__content {
    margin-top: calcSize(2.5);
  }
  .svc-question__content-actions {
    height: calcSize(4);
    & > .sv-action-bar {
      margin-bottom: calcSize(-2);
    }
  }
}
.svc-question__content--title-hidden {
  padding-top: calcSize(5);
}

.svc-question__content {
  .sd-selectbase:not(.sd-imagepicker) {
    padding-left: calcSize(5);
    margin-left: calcSize(-5);
  }

  .sd-table .sd-selectbase:not(.sd-imagepicker) {
    overflow-x: visible;
    padding-left: 0;
    margin-left: 0;
  }

  .sd-selectbase--multi-column {
    overflow: initial;
  }

  .sd-question.sd-question--table {
    overflow-x: initial;
  }

  .sd-multipletext__item-title {
    color: $foreground;
  }

  .sd-multipletext__item-container.sd-input:focus-within {
    box-shadow: $shadow-inner;
  }

  .sd-matrixdynamic__btn.sd-matrixdynamic__add-btn {
    color: $foreground;
    opacity: 0.25;
  }

  .sjs_sp_placeholder {
    user-select: all;
    pointer-events: all;
  }
}

.svc-question__content > div,
.svc-question__placeholders > div {
  overflow: visible;
}
.svc-question--enter, .svc-question--leave {
  animation-name: fadeIn, moveInWithOverflow;
  animation-fill-mode: forwards;
  animation-direction: var(--animation-direction);
  animation-timing-function: var(--animation-timing-function);
  animation-duration: var(--fade-animation-duration), var(--move-animation-duration);
  animation-delay: var(--fade-animation-delay), var(--move-animation-delay);
}
.svc-question--enter {
    opacity: 0;
  --fade-animation-duration: #{$svc-expand-fade-in-duration};
  --move-animation-duration:  #{$svc-expand-move-in-duration};
  --fade-animation-delay: #{$svc-expand-fade-in-delay};
  --move-animation-delay: 0s;
  --animation-timing-function: #{$ease-out};
  --animation-direction: normal;
}
.svc-question--leave {
  --fade-animation-duration: #{$svc-collapse-fade-out-duration};
  --move-animation-duration:  #{$svc-collapse-move-out-duration};
  --fade-animation-delay: 0s;
  --move-animation-delay: #{$svc-collapse-move-out-delay};
  --animation-direction: reverse;
  --animation-timing-function: #{$reverse-ease-out};
}

@keyframes changeAlignSelf {
  from {
    align-self: flex-start;
  }
  to {
    align-self: stretch;
  }
}

.svc-question--enter, .svc-question--leave {
  &.svc-question__adorner
   {
    opacity: 1;
    animation-name: changeAlignSelf;
    animation-duration: 0s;
  }
}
.svc-question--enter {
  &.svc-question__adorner {
    align-self: flex-start;
    animation-delay: var(--move-animation-duration);
   }
}

.svc-question--leave {
  &.svc-question__adorner {
    animation-delay: var(--move-animation-delay);
   }
}

.svc-creator--disable-animations {
  .svc-question--enter, .svc-question--leave {
    animation: none;
  }
}

.svc-question__content {
  .sd-question:not(.sd-question--paneldynamic) {
    .sd-panel__content {
      overflow: auto;
    }

    .sd-table__cell--detail-panel {
      .sd-panel__content {
        overflow: unset;
      }
    }
  }
}

.svc-panel__placeholder {
  @include lbrDefaultFont;
  color: var(--ctr-survey-placeholder-text-color, $foreground-light);
  text-wrap: wrap;
  text-align: center;
}

.svc-question__content-actions {
  position: relative;
  opacity: 0;
  height: calcSize(6);
  //inset-inline-start: calcSize(4); // left
  //inset-inline-end: calcSize(3.5); // right
  margin-left: calcSize(-1);
  margin-top: auto;
  width: calc(100% + 2.5 * #{$base-unit});
  transition: opacity $creator-transition-duration;

  &:focus-within {
    opacity: 1;
  }
  & > .sv-action-bar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    margin-top: calcSize(2);
  }

  .svc-action-bar-item--right:first-of-type {
    margin-inline-start: auto;
  }
}

.svc-dropdown-action--convertTo {
  max-width: max-content;

  .svc-survey-element-toolbar__item {
    flex-direction: row-reverse;
  }

  .sv-action-bar-item--icon {
    max-width: 100%;
    padding: calcSize(0.5);
  }

  .svc-survey-element-toolbar-item__title {
    display: inline-block;
    justify-content: left;
  }

  .svc-survey-element-toolbar-item__icon {
    &:last-child {
      margin-inline-start: 0;
    }
  }

  .svc-survey-element-toolbar-item__icon:last-child {
    use {
      fill: $primary;
    }
  }
}

.svc-dropdown-action--convertTo.sv-action--hidden {
  min-width: 0;
}

.svc-dropdown-action--convertTo-last {
  margin-inline-end: auto; // margin-right
}

.svc-survey-element-toolbar__dots-item {
  width: calcSize(4);
  margin-left: calcSize(1);
  margin-right: calcSize(0.5);

  &.sv-action--hidden {
    width: 0;
    margin: 0;

    .sv-action__content {
      display: none;
    }
  }

  .sv-dots__item {
    margin-left: 0;
  }

  .sv-list__item-icon {
    --ctr-survey-question-toolbar-item-icon-width: calc(
      var(--ctr-survey-question-panel-toolbar-item-icon-container-width) -
        var(--ctr-survey-question-panel-toolbar-item-icon-padding-right) -
        var(--ctr-survey-question-panel-toolbar-item-icon-padding-left)
    );
    --ctr-survey-question-toolbar-item-icon-height: calc(
      var(--ctr-survey-question-panel-toolbar-item-icon-container-height) -
        var(--ctr-survey-question-panel-toolbar-item-icon-padding-top) -
        var(--ctr-survey-question-panel-toolbar-item-icon-padding-bottom)
    );

    width: var(--ctr-survey-question-toolbar-item-icon-width, calcSize(2));
    height: var(--ctr-survey-question-toolbar-item-icon-height, calcSize(2));
  }

  .sv-list__item:hover > .sv-list__item-body .sv-svg-icon use,
  .sv-svg-icon use {
    fill: $secondary;
  }
}

.svc-question__content:focus,
.svc-hovered > .svc-question__content {
  box-shadow: 0 0 0 var(--ctr-survey-question-panel-border-width-hovered, 2px)
    var(--ctr-survey-question-panel-border-color-hovered, $secondary-light);
}

.svc-question__content--selected:not(.svc-question__content--dragged) {
  box-shadow: 0 0 0 var(--ctr-survey-question-panel-border-width-selected, 2px)
    var(--ctr-survey-question-panel-border-color-selected, $secondary);
}

.svc-question__content--collapsed-drag-over-inside:not(.svc-question__content--dragged) {
  box-shadow: 0 0 0 2px $primary;
  background: $primary-light;
  animation: collapsed-drag-over-inside-blinking 1s;
}

@keyframes collapsed-drag-over-inside-blinking {
  0% {
    background: $primary-light;
  }
  40% {
    background: $primary-light;
  }
  50% {
    background: transparent;
  }
  55% {
    background: $primary-light;
  }
  65% {
    background: transparent;
  }
  70% {
    background: $primary-light;
  }
  80% {
    background: transparent;
  }
  85% {
    background: $primary-light;
  }
  100% {
    background: $primary-light;
  }
}

.svc-question__content--selected,
.svc-hovered > .svc-question__content {
  & > .svc-question__content-actions {
    opacity: 1;
    &.svc-question--enter {
      opacity: 0;
    }
  }
}

.svc-question__content--dragged {
  opacity: var(--ctr-survey-question-panel-opacity-hidden, 0.25);

  & > .svc-question__content-actions {
      opacity: 0;
    }
}

.svc-creator--mobile {
  .svc-question__content-actions {
    overflow: hidden;
    height: calcSize(1);
    & > .sv-action-bar {
      margin-top: calcSize(1);
    }
    &.svc-question--enter {
      animation-name: empty, moveInWithOverflow;
    }
  }
  .svc-question__content {
    padding: calcSize(2) calcSize(3) calcSize(2);  
  }
  .svc-element__header--hidden {
    margin-top: calcSize(2);
    margin-bottom: calcSize(2);
    padding: 0 calcSize(3);
  }
  .svc-question__adorner--selected {
    & > .svc-element__header--hidden {
      margin-top: calcSize(3);
      margin-bottom: calcSize(3);
    } 
  }
  .svc-question__content--selected {
    padding-top: calcSize(3);
    padding-bottom: calcSize(2);
    & > .svc-question__content-actions {
     height: calcSize(6);
     overflow: visible;

    & > .sv-action-bar {
      margin-top: calcSize(2);
     }
     &.svc-question--enter {
       animation-name: fadeIn, moveInWithOverflow;
     }
   }
  }
  &.svc-creator--disable-animations {
    .svc-question--enter {
      animation: none;
    }
  } 
}


.svc-question__content {
  .sd-question.sd-question--table {
    margin-top: calcSize(-1);
    padding-top: calcSize(1);
  }
}

.svc-dragged-element-shortcut {
  //min-width: 100px;
  height: auto;
  border-radius: var(--ctr-toolbox-item-corner-radius, calcSize(12.5));
  background-color: var(--ctr-toolbox-item-background-color-floating, $background);
  color: var(--ctr-toolbox-item-text-color-hovered, $foreground);
  padding: calcSize(1.5) calcSize(2);
  cursor: grabbing;
  position: absolute;
  z-index: 1000;
  box-shadow: var(--ctr-toolbox-item-shadow-floating-1-offset-x, 0px) var(--ctr-toolbox-item-shadow-floating-1-offset-y, 8px) var(--ctr-toolbox-item-shadow-floating-1-blur, 16px) var(--ctr-toolbox-item-shadow-floating-1-spread, 0px) var(--ctr-toolbox-item-shadow-floating-1-color, rgba(0, 76, 68, 0.10)), var(--ctr-toolbox-item-shadow-floating-2-offset-x, 0px) var(--ctr-toolbox-item-shadow-floating-2-offset-y, 16px) var(--ctr-toolbox-item-shadow-floating-2-blur, 32px) var(--ctr-toolbox-item-shadow-floating-2-spread, 0px) var(--ctr-toolbox-item-shadow-floating-2-color, var(--ctr-shadow-large-color, rgba(0, 0, 0, 0.10)));
  @include disableUserSelect;
  //max-width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: content-box;
}

.svc-dragged-element-shortcut .sv-svg-icon use {
  fill: var(--ctr-toolbox-item-icon-color-hovered, $primary);
}

.svc-dragged-element-shortcut--selected {
  border: 2px solid var(--ctr-toolbox-item-border-color-floating, $secondary);
}

.svc-dragged-element-shortcut__text {
  @include ctrSmallBoldFont;
  line-height: calcSize(2);
}

.svc-dragged-element-shortcut--has-icon {
  padding: calcSize(1) calcSize(1.5);
  padding-right: calcSize(3.5);

  .svc-dragged-element-shortcut__text {
    line-height: calcSize(3);
    margin-inline-start: calcSize(4);
  }
}

.svc-dragged-element-shortcut__icon {
  position: absolute;
  width: calcSize(3);
  height: calcSize(3);
}

.svc-question__content--drag-over-inside,
.svc-hovered > .svc-question__content--drag-over-inside {
  .svc-panel__placeholder_frame {
    box-shadow: 0 0 0 1px $primary;
    border: 1px solid $primary;
    background: $primary-light;

    & > .svc-question__content-actions {
      opacity: 1;
    }
  }
}

.svc-question__drop-indicator {
  position: absolute;
  background: $secondary;
  opacity: 0;
  transition: opacity $creator-transition-duration;
}

.svc-question__drop-indicator--left,
.svc-question__drop-indicator--right {
  top: 0;
  height: 100%;
  width: 0px;
}

.svc-question__drop-indicator--top,
.svc-question__drop-indicator--bottom {
  left: 0;
  width: 100%;
  height: 0px;
}

.svc-question__drop-indicator--left {
  left: calc(calcSize(-1) - (calcSize(0.25) / 2));
}

.svc-question__drop-indicator--right {
  right: calc(calcSize(-1) - (calcSize(0.25) / 2));
}

.svc-question__drop-indicator--top {
  top: calc(calcSize(-1) - (calcSize(0.25) / 2));
}

.svc-question__drop-indicator--bottom {
  bottom: calc(calcSize(-1) - (calcSize(0.25) / 2));
}

.svc-question__content--drag-over-left {
  > .svc-question__drop-indicator--left {
    opacity: 1;
    width: calcSize(0.25);
  }
}

.svc-question__content--drag-over-right {
  > .svc-question__drop-indicator--right {
    opacity: 1;
    width: calcSize(0.25);
  }
}

.svc-question__content--drag-over-top {
  > .svc-question__drop-indicator--top {
    opacity: 1;
    height: calcSize(0.25);
  }
}

.svc-question__content--drag-over-bottom {
  > .svc-question__drop-indicator--bottom {
    opacity: 1;
    height: calcSize(0.25);
  }
}

.sd-panel {
  .svc-question__content--drag-over-left {
    .svc-question__drop-indicator--left {
      left: calc(calcSize(-1) + calcSize(0.25));
    }
  }

  .svc-question__content--drag-over-right {
    .svc-question__drop-indicator--right {
      right: calc(calcSize(-1) + calcSize(0.25));
    }
  }
}

.svc-question__content--panel,
.sd-panel__content {
  .sd-row > div:first-child {
    .svc-question__content--drag-over-left {
      margin-left: 8px;
      width: calc(100% - 8px);
    }
  }

  .sd-row > div:last-child {
    .svc-question__content--drag-over-right {
      margin-right: 8px;
      width: calc(100% - 8px);
    }
  }

  .svc-question__content--drag-over-top {
    .svc-question__drop-indicator--top {
      top: calc(calcSize(-1) + calcSize(0.25));
    }
  }

  .svc-question__content--drag-over-bottom {
    .svc-question__drop-indicator--bottom {
      bottom: calc(calcSize(-1) + calcSize(0.25));
    }
  }
}

.svc-question__content-actions {
  & > .sv-action-bar {
    box-sizing: border-box;
    padding: 0;
    justify-content: center;
  }
}

svc-question,
.svc-question {
  sv-action-bar,
  .sv-action-bar {
    padding: 0;
  }
}

.svc-panel__edge {
  position: absolute;
  border: 1px solid orange;
  height: 10px;
  width: 100%;
}

.svc-panel__edge--top {
  top: 0;
}

.svc-panel__edge--bottom {
  bottom: 0;
}

// reset styles for drag-drop-ghost-survey-element to avoid layout jumping while dragging
.svc-question__adorner--start-with-new-line {
  &[data-sv-drop-target-survey-element="sv-drag-drop-ghost-survey-element-name"] {
    height: 100%;
    overflow: hidden;
    min-width: 4px;

    .svc-question__content.svc-question__content.svc-question__content {
      border: 0;
      padding: 0;
    }
  }
}

[data-sv-drop-target-survey-element="sv-drag-drop-ghost-survey-element-name"] {
  border: none;
  height: 0;

  .svc-question__drag-area {
    display: none;
  }

  .svc-question__drag-element {
    display: none;
  }

  .svc-question__content {
    background: transparent;
    box-shadow: none;
  }
}

// EO reset styles for drag-drop-ghost-survey-element to avoid layout jumping while dragging

.svc-hovered > .svc-question__content > .svc-question__drag-area > .svc-question__drag-element {
  opacity: var(--ctr-survey-question-panel-drag-area-drag-indicator-opacity, 0.5);
}

.svc-question__content.svc-question__content--selected > .svc-question__drag-area > .svc-question__drag-element {
  opacity: var(--ctr-survey-question-panel-drag-area-drag-indicator-opacity, 0.5);
  z-index: 1;
}
.svc-element__header--hidden {
  display: block;
  box-sizing: border-box;
  padding: 0 calcSize(5);
  margin: calcSize(4) 0;
  z-index: -1;
  width: 100%;
  .svc-string-editor,
  .svc-fake-title,
  sv-ng-string > .svc-string-editor {
    display: inline-flex;
    &::before {
      content: "(";
    }
    &::after {
      content: ")";
    }
  }
  .svc-element__title--hidden {
    opacity: 0;
    transition: opacity 150ms;
  }
}
.svc-element__header--lazy,
.svc-element__header--hidden.sd-question__header--location--left {
  .svc-string-editor,
  sv-ng-string > .svc-string-editor {
    display: inline-block;
    &::before, &::after {
      content: none;
    }
  }
}

.svc-question__adorner {
  &.svc-question__adorner--collapsed {
    & > .svc-element__header--hidden {
      z-index: 1;
      & > .svc-element__title--hidden {
        opacity: 1;
      }
    }

    .svc-question__content--collapsed {

      &>.svc-question__dropdown-choices--wrapper,
      &>.svc-panel__placeholder_frame-wrapper,
      &>.svc-question__content-actions,
      &>.svc-panel__add-new-question-container,
      &>.sd-file,
      &>sv-ng-file-question {
        display: none;
      }
      .svc-question__drop-indicator {
        display: block;
      }
      .svc-question__drag-area {
        display: flex;
      }
      .svc-content-wrapper {
        display: flex;
      }
      .sd-element {
        &>*:not(.sd-element__header) {
          display: none;
        }
      }

      .sd-element__header {
        &>*:not(.sd-element__title) {
          display: none;
        }
      }

      .sd-question__header--location--left {
        display: none;
      }
    }
  }

  .svc-question__content--collapsed {
    flex-grow: 0;
    padding-bottom: calcSize(4);
    padding-top: calcSize(4);

    .sd-element__header,
    .sd-element--complex>.sd-element__header {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.svc-creator--mobile {
  .svc-question__top-actions {
    padding: 0;
  }
  
  .svc-question__adorner {
    .svc-question__content--collapsed {
      padding-bottom: calcSize(2);
      padding-top: calcSize(2);

      &.svc-question__content--selected {
        padding-top: calcSize(3);
        padding-bottom: calcSize(3);
      }
    }
  }
}

.svc-question__drag-area {
  position: absolute;
  cursor: move;
  top: 0;
  left: 0;
  height: calcSize(3.5);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.svc-question__drag-element {
  width: 100%;
  opacity: 0;
  transition: opacity $creator-transition-duration;
  width: var(--ctr-survey-question-panel-drag-area-drag-indicator-width, calcSize(3));
  height: var(--ctr-survey-question-panel-drag-area-drag-indicator-height, calcSize(2));

  use {
    fill: var(--ctr-survey-question-panel-drag-area-drag-indicator-color, $foreground-light);
  }
}

.sd-panel {
  .svc-question__content--image:not(.svc-question__content--empty) {
    padding-left: 1px;
    padding-right: 1px;
  }

  .svc-question__content {
    .sd-table-wrapper::before {
      left: calc(calc(-1 * var(--sd-base-padding)) + 1px);
    }

    .sd-table-wrapper::after {
      right: calc(calc(-1 * var(--sd-base-padding)) + 1px);
    }
  }

  .svc-question__content {
    border-radius: 0;
    box-shadow: none;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: var(--ctr-survey-question-panel-border-width-nested, 1px) dashed var(--ctr-survey-question-panel-border-color-nested, $border);
    }
  }

  .svc-hovered .svc-question__content:not(.svc-question__content--dragged) {
    box-shadow: 0 0 0 var(--ctr-survey-question-panel-border-width-hovered, 2px) var(--ctr-survey-question-panel-border-color-hovered, $secondary-light) inset;
    &:after {
      content: initial;
    }
  }

  .svc-question__content.svc-question__content--selected:not(.svc-question__content--dragged) {
    box-shadow: 0 0 0 var(--ctr-survey-question-panel-border-width-selected, 2px) var(--ctr-survey-question-panel-border-color-selected, $secondary) inset;
    &:after {
      content: initial;
    }
  }
}

.sd-panel .svc-row--ghost.svc-row--ghost .svc-question__content.svc-question__content {
  border: none;
}

.svc-widget__content {
  width: 100%;

  .sd-question__content {
    pointer-events: none;
  }
}

.svc-question__content {
  .sd-paneldynamic__footer,
  .sd-paneldynamic__separator {
    display: none;
  }
}

.svc-panel__placeholder_frame-wrapper {
  container-type: inline-size;
  width: 100%;
  display: flex;
  flex-grow: 1;
  box-sizing: border-box;
  position: relative;
}

.svc-question__content--empty-template {
  .sd-question--paneldynamic,
  .sd-question__content,
  sv-ng-paneldynamic-question,
  .sd-paneldynamic,
  .sd-paneldynamic__panels-container,
  .sd-paneldynamic__panel-wrapper,
  .svc-question__adorner,
  .svc-question__adorner > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .svc-panel__placeholder_frame {
      flex-grow: 1;
    }
  }
}

.sd-paneldynamic__panels-container {
  padding-bottom: 2px;
  margin-bottom: -2px;
}

.svc-panel__placeholder_frame {
  border: 1px dashed $border;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: calcSize(11) calcSize(8) calcSize(10);
  flex-grow: 1;
}

.svc-panel__add-new-question-container {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.svc-panel__add-new-question-wrapper {
  container-type: inline-size;
  width: 100%;
  display: flex;
  justify-content: center;

  .svc-element__add-new-question {
    margin-top: var(--ctr-survey-panel-add-button-margin-top, calcSize(2));
  }

  .svc-element__add-new-question-icon {
    margin: calcSize(0.5);
    display: none;

    use {
      fill: $primary;
    }
  }

  .svc-element__question-type-selector {
    position: absolute;
    inset-inline-end: 0;
    bottom: 0;
    margin: 0;
  }
}

.svc-panel__add-new-question,
.svc-panel__add-new-question-wrapper .svc-element__add-new-question {
  display: flex;
  margin-top: var(--ctr-survey-placeholder-gap, calcSize(2));
  margin-left: 0;
  margin-right: 0;
  width: auto;
  padding: var(--ctr-survey-action-button-padding-top, calcSize(1))
    var(--ctr-survey-action-button-padding-right, calcSize(5))
    var(--ctr-survey-action-button-padding-bottom, calcSize(1))
    var(--ctr-survey-action-button-padding-left, calcSize(5));
  border-radius: var(--ctr-survey-action-button-corner-radius, 1024px);
  justify-content: center;
  align-items: center;

  vertical-align: baseline;
  text-align: center;
  cursor: pointer;
  user-select: none;
  transition:
    background-color $creator-transition-duration,
    background $creator-transition-duration;

  border: none;
  box-shadow: none;

  &:hover {
    outline: none;
  }

  .svc-add-new-item-button__text {
    @include lbrDefaultBoldFont;
    color: var(--ctr-survey-action-button-text-color-positive, $primary);
    text-wrap: nowrap;
  }
}

.svc-panel__add-new-question-icon {
  margin: calcSize(0.5);
  display: none;

  use {
    fill: $primary;
  }
}

.svc-panel__question-type-selector-popup {
  height: calcSize(5);
  width: calcSize(5);
  position: absolute;
  right: 0;
  bottom: 0;
}

@container (max-width: #{$sd-panel-normal-min-width}) {
  .svc-panel__placeholder_frame {
    padding: calcSize(11) calcSize(3) calcSize(10);
  }
}

@container (max-width: #{$sd-panel-medium-min-width}) {
  .svc-panel__placeholder {
    display: none;
  }

  .svc-panel__placeholder_frame {
    padding: calcSize(10) calcSize(3) calcSize(10);

    .svc-panel__add-new-question,
    .svc-element__add-new-question {
      margin: 0;
    }
  }

  .svc-panel__placeholder_frame,
  .svc-panel__add-new-question-container {
    .svc-panel__add-new-question-icon {
      display: block;
      width: var(--ctr-button-contextual-button-icon-width, calcSize(3));
      height: var(--ctr-button-contextual-button-icon-height, calcSize(3));
    }

    .svc-element__question-type-selector {
      display: none;
    }

    .svc-panel__add-new-question,
    .svc-element__add-new-question {
      padding: calcSize(0.5) calcSize(0.5);

      .svc-add-new-item-button__text {
        display: none;
      }
    }
  }
}

.sd-panel {
  .svc-row {
    margin-top: calcSize(1);
  }

  .svc-row:first-of-type {
    margin-top: 0;
  }

  .svc-row .sd-row {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.svc-question__content.svc-question__content--collapsed {
  .sd-element--complex > .sd-element__header--location-top {
    &.svc-element__header--hidden {
      padding: 0;
    }
  }
}

.svc-question__content {
  .sd-element--complex > .sd-element__header--location-top {
    padding-bottom: calc(0.5 * var(--sd-base-vertical-padding));
    padding-top: 0;

    &.svc-element__header--hidden {
      padding-bottom: calcSize(1);
    }

    &:after {
      display: none;
    }
  }

  .sd-panel__content {
    gap: 0;
    padding-top: 0;
  }

  .sd-table__cell--detail-panel .sd-panel__content {
    padding-top: calcSize(1);
  }

  .sd-paneldynamic__panel-wrapper {
    padding: 0;
  }

  .sd-table__cell--actions {
    .sv-action-bar-item:disabled {
      background: $background;
      opacity: 1;

      use {
        fill: $foreground-light;
      }
    }
  }

  .sd-table__row-disabled > .sd-table__cell {
    opacity: 1;
  }
}

.svc-question__content {
  .svc-carry-forward-panel {
    margin-top: calcSize(2);
  }

  .sd-question__content {
    min-width: min(calcSize(8), 100%);
    margin-left: calcSize(-5);
    margin-right: calcSize(-5);
    padding-left: calcSize(5);
    padding-right: calcSize(5);
    overflow-x: auto;
  }

  .sd-question--description-under-input .sd-question__content {
    margin-bottom: calcSize(-1);
    padding-bottom: calcSize(1);
  }

  .sd-composite {
    .sd-question__content {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.svc-question__content--paneldynamic {
  .sd-question__content {
    padding-bottom: 2px;
    margin-bottom: -2px;
    padding-top: calcSize(1);
    margin-top: calcSize(-1);
  }

  .sd-paneldynamic__panels-container {
    padding-top: calcSize(1);
    margin-top: calcSize(-1);
  }
}

.svc-question__adorner {
  .sv-ranking:not(.sv-ranking--select-to-rank) {
    .svc-question__content--ranking {
      .svc-carry-forward-panel {
        margin-top: 0;
      }
    }
  }

  .sv-ranking {
    .sv-ranking__container-placeholder {
      padding-top: calcSize(2);
      padding-bottom: calcSize(2);
    }
  }

  .sv-ranking--select-to-rank-vertical {
    .sv-ranking__container--empty {
      padding-top: 0;
      padding-bottom: 0;
    }

    .sv-ranking__container--from {
      .sv-ranking__container-placeholder {
        padding-bottom: calcSize(3);
      }
    }
  }
}

.svc-question__top-actions {
  padding: calcSize(0.5);
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
}

.svc-survey-element-top-toolbar__item {
  @include defaultActionButtonSettings;
  --thm-survey-question-panel-toolbar-item-padding-top-small: calc(
    var(--ctr-survey-question-panel-toolbar-item-padding-top-small) +
      var(--ctr-survey-question-panel-toolbar-item-icon-padding-top)
  );
  --thm-survey-question-panel-toolbar-item-padding-right-small: calc(
    var(--ctr-survey-question-panel-toolbar-item-padding-right-small) +
      var(--ctr-survey-question-panel-toolbar-item-icon-padding-right)
  );
  --thm-survey-question-panel-toolbar-item-padding-bottom-small: calc(
    var(--ctr-survey-question-panel-toolbar-item-padding-bottom-small) +
      var(--ctr-survey-question-panel-toolbar-item-icon-padding-bottom)
  );
  --thm-survey-question-panel-toolbar-item-padding-left-small: calc(
    var(--ctr-survey-question-panel-toolbar-item-padding-left-small) +
      var(--ctr-survey-question-panel-toolbar-item-icon-padding-left)
  );

  padding: var(--thm-survey-question-panel-toolbar-item-padding-top-small, calcSize(0.5))
    var(--thm-survey-question-panel-toolbar-item-padding-right-small, calcSize(0.5))
    var(--thm-survey-question-panel-toolbar-item-padding-bottom-small, calcSize(0.5))
    var(--thm-survey-question-panel-toolbar-item-padding-left-small, calcSize(0.5));
  border-radius: var(--ctr-survey-question-panel-toolbar-item-corner-radius, 2px);
  opacity: var(--ctr-survey-question-panel-toolbar-item-opacity-muted, 0.5);
  background-color: transparent;

  &:hover,
  &:focus {
    opacity: initial;
    background-color: var(--ctr-survey-question-panel-toolbar-item-background-color-hovered, $background-dim);
  }

  use {
    fill: $foreground-dim-light;
  }
}

.svc-question__adorner--collapse-onhover.svc-hovered > .svc-question__content,
.svc-question__adorner--collapse-onhover > .svc-question__content--selected,
.svc-question__adorner--collapse-always > .svc-question__content {
  & > .svc-question__drag-area > .svc-question__top-actions {
    visibility: visible;
  }
}

.sd-row--enter.sd-row--enter.sd-row--enter.sd-row--enter {
  .svc-question__content--selected {
    box-shadow: $shadow-small;

    .svc-question__content-actions {
      opacity: 0;
    }
  }
}

.sd-panel {
  .sd-row--enter.sd-row--enter.sd-row--enter.sd-row--enter {
    .svc-question__content--selected {
      border-radius: 0;
      border: 1px dashed $border;
      box-shadow: none;
    }
  }
}
