.spg-question {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  border-radius: var(--ctr-editor-corner-radius);
}

.spg-question.spg-question--disabled {
  background-color: var(--ctr-editor-background-color-disabled);
}

.spg-question__header {
  width: 100%;
}

.spg-question--disabled .spg-question__header {
  opacity: var(--ctr-editor-label-opacity-disabled, 0.25);
}

.spg-question__title {
  @include ctrDefaultFont;
  width: 100%;
  margin: 0;
  padding: var(--ctr-label-padding-top, calcSize(1)) var(--ctr-label-padding-right, 0px)
    var(--ctr-label-padding-bottom, calcSize(1)) var(--ctr-label-padding-left, 0px);
  color: var(--ctr-label-text-color, $foreground-light);
  box-sizing: border-box;
}
.spg-question__title.sd-element__title--disabled {
  color: var(--ctr-caption-with-actions-text-color-disabled, $foreground-dim);
}

.spg-question__content {
  width: 100%;
  color: $primary;
  cursor: pointer;
}

.spg-text__content,
.spg-comment__content {
  position: relative;
}

.spg-question__content:focus-within .spg-remaining-character-counter {
  display: flex;
}

.spg-remaining-character-counter {
  @include ctrSmallFont;
  display: none;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0px;
  color: $foreground-light;
  position: absolute;
  inset-inline-end: calcSize(0.75);
  inset-block-end: calcSize(0.5);
}

.spg-question__description {
  @include ctrSmallFont;
  color: var(--ctr-caption-with-actions-description-text-color, $foreground-dim-light);
  padding-bottom: calcSize(2);
  white-space: normal;
}

.spg-question--disabled .spg-question__description {
  color: var(--ctr-caption-with-actions-description-text-color, $foreground-dim-light);
}

.spg-row-narrow__question {
  margin-top: calcSize(-2);
}

.spg-row--multiple {
  & > div > .spg-row-narrow__question {
    margin-top: calcSize(-1);
  }
}

.spg-question--location--left {
  flex-direction: row;
  background: var(--ctr-editor-background-color, $background);
  box-shadow: inset 0 0 0 var(--ctr-editor-border-width, 1px) var(--ctr-editor-border-color, $border-inside);
  line-height: calcSize(6);
  vertical-align: middle;
  align-items: stretch;
  border-radius: var(--ctr-editor-corner-radius, 0px);


  &:focus-within {
    box-shadow: inset 0 0 0 var(--ctr-editor-border-width-focused, 2px) var(--ctr-editor-border-color-focused, $primary);
  }
}

.spg-question--disabled.spg-question--location--left .spg-question__title {
  color: var(--ctr-editor-content-text-color-disabled, $foreground-dim);
}

.spg-question__header--location--left {
  width: max-content;
  box-sizing: border-box;
  max-width: 50%;
  flex: 0 0;
  display: flex;
  align-items: center;
  padding-inline-start: var(--ctr-editor-padding-left, 0);

  .spg-question__title {
    padding: 
      var(--ctr-editor-label-padding-top, calcSize(1)) 
      var(--ctr-editor-label-padding-right, calcSize(2)) 
      var(--ctr-editor-label-padding-bottom, calcSize(1)) 
      var(--ctr-editor-label-padding-left, calcSize(2));
    border-inline-end: 1px solid $border;
    display: inline-block;
    color: var(--ctr-editor-label-color, $foreground-dim-light);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .sv-string-viewer {
      white-space: nowrap;
    }
  }
}

.spg-question__content--left {
  flex: 2;

  .spg-input.spg-input.spg-input {
    background-color: transparent;
    box-shadow: none;
    border: none;

    &:focus,
    &:focus-within {
      box-shadow: none;
    }
  }
}

.spg-row--multiple {
  display: flex;
  gap: var(--ctr-editor-layout-gap, calcSize(1));
  flex-wrap: wrap;

  & > div {
    flex-grow: 1;
    min-width: calcSize(25.5);

    input {
      min-width: calc(max(17 * #{$base-unit}, 100%));
      width: 0;
    }
  }
}

.spg-question__erbox {
  @include ctrDefaultFont;
  padding: var(--ctr-error-message-padding-top, calcSize(1)) var(--ctr-error-message-padding-right, calcSize(1.5))
    var(--ctr-error-message-padding-bottom, calcSize(1)) var(--ctr-error-message-padding-left, calcSize(1.5));

  color: var(--ctr-error-message-text-color, $foreground);
  background-color: var(--ctr-error-message-background-color, $red-light);
  border-radius: var(--ctr-error-message-corner-radius, calcSize(0.5));
}

.spg-question__erbox,
.spg-question__erbox>svc-question-error,
sv-question-error {
  & > div {
    display: flex;
    gap: var(--ctr-error-message-gap, calcSize(1));
  }
}

.spg-question__erbox-icon {
  width: var(--ctr-error-message-icon-width, calcSize(3));
  height: var(--ctr-error-message-icon-height, calcSize(3));
  
  .sv-svg-icon {
    vertical-align: top;
    width: var(--ctr-error-message-icon-width, calcSize(3));
    height: var(--ctr-error-message-icon-height, calcSize(3));
  }

  use {
    fill: var(--ctr-error-message-icon-color, $red);
  }
}

.spg-question__erbox--location--bottom {
  margin-top: calcSize(1);
}

.spg-restfull,
.spg-masksettings {
  .spg-panel__content {
    gap: calcSize(2);

    .spg-row {
      margin-top: 0;
    }
  }
}

.spg-question--highlighted {
  .spg-input,
  .spg-input-container,
  .spg-question--location--left,
  .spg-table__cell:not(.spg-table__cell--detail-panel) .spg-input {
    box-shadow: inset 0 0 0 var(--ctr-editor-border-width-highlighed, 2px) var(--ctr-editor-border-color-highlighted, $secondary);
  }

  .spg-checkbox__control:focus + .spg-checkbox__rectangle,
  .spg-matrixdynamic__content.spg-text__content {
    outline: var(--ctr-editor-border-width-highlighed, 2px) solid var(--ctr-editor-border-color-highlighted, $secondary);
    outline-offset: calc(-1 * var(--ctr-editor-border-width-highlighed, 2px));
    border-radius: var(--ctr-data-table-corner-radius, 0px);

    .spg-input {
      box-shadow: none;
    }
  }

  .svc-action-button {
    border: var(--ctr-actionbar-button-border-width-highlighed, 2px) solid var(--ctr-actionbar-button-border-color-highlighted, $secondary);
  }

  .sv-button-group {
    box-shadow: 0 0 0 1px var(--ctr-editor-border-color-highlighted, $secondary);
    border-color: var(--ctr-editor-border-color-highlighted, $secondary);
  }

  .spg-checkbox__control + .spg-checkbox__rectangle {
    outline: var(--ctr-editor-border-width-highlighed, 2px) solid var(--ctr-editor-border-color-highlighted, $secondary);
    outline-offset: calc(-1 * var(--ctr-editor-border-width-highlighed, 2px));
  }

  .spg-button-group::after {
    box-shadow: inset 0 0 0 var(--ctr-button-group-border-width-focused, 2px) var(--ctr-editor-border-color-highlighted, $secondary);
  }
}