.spg-matrixdynamic__drag-element {
  padding: calcSize(2);

  &:hover {
    background-color: $background;
  }

  &:after {
    content: " ";
    display: block;
    height: calcSize(0.5);
  }
}

.spg-drag-element__svg {
  width: var(--ctr-data-table-row-drag-area-icon-width, calcSize(3));
  height: var(--ctr-data-table-row-drag-area-icon-height, calcSize(3));
  padding-top: var(--ctr-data-table-row-drag-area-padding-top, calcSize(1)); 
  padding-inline-end: var(--ctr-data-table-row-drag-area-padding-right, calcSize(1));
  padding-bottom: var(--ctr-data-table-row-drag-area-padding-bottom, calcSize(1));
  padding-inline-start: var(--ctr-data-table-row-drag-area-padding-left, calcSize(1));

  display: block;
  opacity: 0.5;

  use {
    fill: var(--ctr-data-table-row-drag-area-icon-color, $foreground-light);
  }
}

.spg-matrixdynamic__drag-drop-ghost-position-top,
.spg-matrixdynamic__drag-drop-ghost-position-bottom {
  position: relative;
}

.spg-matrixdynamic__drag-drop-ghost-position-top::after,
.spg-matrixdynamic__drag-drop-ghost-position-bottom::after {
  content: "";
  width: 100%;
  height: calcSize(0.5);
  background-color: $secondary;
  position: absolute;
  left: 0;
}

.spg-matrixdynamic__drag-drop-ghost-position-top::after {
  top: 0;
}

.spg-matrixdynamic__drag-drop-ghost-position-bottom::after {
  bottom: 0;
}

.spg-matrixdynamic__placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--ctr-property-grid-background-color, $background);
  border: 1px solid var(--ctr-survey-placeholder-border-color, $border);
  border-radius: var(--ctr-data-table-placeholder-corner-radius);
  box-sizing: border-box;
  padding: var(--ctr-survey-placeholder-padding-top-with-button, calcSize(6))
    var(--ctr-survey-placeholder-padding-right, 0) var(--ctr-survey-placeholder-padding-bottom-with-button, calcSize(6))
    var(--ctr-survey-placeholder-padding-left, 0);
  gap: var(--ctr-survey-placeholder-gap, calcSize(0.5));

  .spg-matrixdynamic__add-btn {
    display: initial;
    margin: 0;
  }
}

.spg-matrixdynamic__placeholder-text {
  @include ctrDefaultFont;
  color: var(--ctr-survey-placeholder-text-color, $foreground-light);
  text-align: center;
}

.spg-matrixdynamic__add-btn {
  @include ctrDefaultBoldFont;
  display: none;
  appearance: none;
  border: none;
  margin-top: calcSize(0.5);
  background: transparent;
  color: var(--ctr-survey-action-button-text-color-positive, $primary);
}

.spg-smiley-icon {
  width: calcSize(3);
  height: calcSize(3);
  margin: calcSize(1);
  border-radius: 100px;
  border: 1px solid var(--ctr-actionbar-button-icon-color, $foreground);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: calcSize(4);

  svg {
    width: calcSize(1.5);
    height: calcSize(1.5);
    fill: var(--ctr-actionbar-button-icon-color, $foreground);
  }
}

.spg-matrixdynamic__dragged-row {
  cursor: grabbing;
  position: absolute;
  z-index: 10000;

  .spg-table__row {
    display: flex;
    align-items: center;
    flex: 1 0 0;
    border-radius: var(--ctr-data-table-row-corner-radius-floating, 0px);
    background: var(--ctr-data-table-row-background-color, $background);
    box-shadow:
      var(--ctr-data-table-row-shadow-floating-1-offset-x, 0px)
        var(--ctr-data-table-row-shadow-floating-1-offset-y, 2px) var(--ctr-data-table-row-shadow-floating-1-blur, 6px)
        var(--ctr-data-table-row-shadow-floating-1-spread, 0px)
        var(--ctr-data-table-row-shadow-floating-1-color, rgba(0, 0, 0, 0.1)),
      var(--ctr-data-table-row-shadow-floating-2-offset-x, 0px)
        var(--ctr-data-table-row-shadow-floating-2-offset-y, 8px) var(--ctr-data-table-row-shadow-floating-2-blur, 16px)
        var(--ctr-data-table-row-shadow-floating-2-spread, 0px)
        var(--ctr-data-table-row-shadow-floating-2-color, rgba(0, 0, 0, 0.1));
  }
}
